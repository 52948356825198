import React, { FC } from "react";

import Icon from "components/Shared/Icons/Icon/Icon";
import Button from "components/Shared/Button/Button";
import ReactDOM from "react-dom";
import Spinner from "../../Spinner/Spinner";

interface IProps {
  header: string;
  title?: string;
  message: string;
  icon?: IconType;
  onClose: () => void;
  button: string;
  onClick: () => void;
  background?: string;
  children?: React.ReactNode;
  customClass?: string;
  disabled?: boolean;
}

const CustomModal: FC<IProps> = ({
  header,
  title,
  message,
  icon,
  onClose,
  button,
  onClick,
  background,
  children,
  customClass,
  disabled
}) => {
  return ReactDOM.createPortal(
    <div className={`custom-modal-container  ${customClass}`}>
      <div className={`custom-modal ${customClass}`}>
        <div
          className="custom-modal-topbar"
          style={{
            background
          }}
        >
          <div className="custom-modal-topbar-header">
            {icon && (
              <Icon
                icon={icon}
                style={{
                  marginRight: "10px"
                }}
              />
            )}
            <div>{header}</div>
          </div>
          <Icon
            icon="close"
            style={{
              cursor: "pointer"
            }}
            onClick={onClose}
          />
        </div>
        <div className="custom-modal-content-container full-view">
              <div className="custom-modal-content">
                {title && (
                    <div className="custom-modal-header" style={{ color: background }}>
                      {title}
                    </div>
                )}
                <div className="custom-modal-message">{message}</div>
                {children}
              </div>
          {
          disabled ? (
                  <Spinner showSpinner={disabled} />
              ) :
          <Button disabled={disabled} name={button} onClick={onClick} />
          }
        </div>
      </div>
    </div>,
    document.body
  );
};

export default CustomModal;
