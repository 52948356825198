import React, {FC, useEffect, useRef, useState} from "react";
import Iframe from "react-iframe";
import { useDispatch } from "react-redux";

import {
  handleFetchStudentTests,
  handleScoreTribyteTest
} from "Store/tests/actions";
import CustomModal from "../CustomModal/CustomModal";
import Spinner from "../../Spinner/Spinner";
import Router from "next/router";

interface IProps {
  url: string;
  onClose: () => void;
  test: Test;
}

const DigitalTestModal: FC<IProps> = ({ url, test, onClose }) => {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const eventListener = async (message) => {
    if (message.data === "end") {
      setShowSpinner(true);
      setTimeout(function () {
        dispatch(handleScoreTribyteTest());
        setTimeout(function () {
          onCloseDigitalTest()
        }, 2000);
      }, 5000);
    }
    if (message.data === "pause") {
      dispatch(handleFetchStudentTests());
      onCloseDigitalTest()
    }
  }
  const popStateListener = (message) => {
    onCloseDigitalTest()
    history.go(1);
  };

  useEffect(() => () => window.removeEventListener('message', eventListener), [])
  useEffect(() => () => window.removeEventListener('popstate', popStateListener), [])

  useEffect(() => {
    window.addEventListener("message", eventListener);
    window.addEventListener("popstate", popStateListener);
  }, []);

  function onCloseDigitalTest() {
    window.removeEventListener('message', eventListener);
    window.removeEventListener('popstate', popStateListener);
    onClose();
  }

  return (
    <div className="digital-test-container">
          <CustomModal
              onClose={onCloseDigitalTest}
              header="Digital Test"
              onClick={() => {}}
              button=""
              message=""
              customClass="full-view"
          >
            {showSpinner ? (
                <Spinner showSpinner={showSpinner} />
            ) : (
              <Iframe
                  url={url}
                  width="100%"
                  height="100%"
                  id="iframe"
                  className=""
                  position="relative"
                  sandbox={["allow-scripts", "allow-modals", "allow-presentation", "allow-same-origin"]}
              />
            )
            }
          </CustomModal>
    </div>
  );
};

export default DigitalTestModal;
