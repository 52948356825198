export const parseHTML = (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const table = doc.querySelector('#questions-table table tbody');

  if (!table) {
    throw new Error('Table not found');
  }

  const rows = Array.from(table.querySelectorAll('tr'));
  return rows.map(row => parseTableRow(row));
}

export const parseTableRow = (row) => {
  const problemNumber = parseInt(row.querySelector('th').textContent.trim(), 10);
  const subject = row.querySelector('td:nth-child(2)').textContent.trim();
  const correctAnswer = row.querySelector('td:nth-child(3) div').textContent.trim();
  const studentAnswerText = row.querySelector('td:nth-child(4) p').textContent.trim();

  let studentAnswer = null;
  let result = null;

  if (studentAnswerText === 'Omitted') {
    studentAnswer = '';
    result = 'Omitted';
  } else {
    const [answer, res] = studentAnswerText.split(';').map(s => s.trim());
    studentAnswer = answer;
    result = res.toLowerCase() === 'correct' ? 'Correct' : 'Incorrect';
  }

  const moduleClass = row.className.match(/module-(\d+)/);
  const moduleNumber = moduleClass ? parseInt(moduleClass[1], 10) : null;

  return {
    problemNumber,
    subject,
    studentAnswer,
    correctAnswer,
    result,
    moduleNumber
  };
}

export const organizeData = (parsedData) => {
  const sections = {};

  // Group by subject and module number
  parsedData.forEach(item => {
    const key = `${item.subject}_${item.moduleNumber}`;
    if (!sections[key]) {
      sections[key] = {
        subjectName: item.subject,
        moduleNumber: item.moduleNumber,
        responses: []
      };
    }
    sections[key].responses.push(item);
  });

  // Convert the sections object into an array and sort by subject and module number
  const sortedSections = Object.values(sections).sort((a, b) => {
    // Ensure "Reading and Writing" comes before "Math"
    if (a.subjectName === b.subjectName) {
      return a.moduleNumber - b.moduleNumber;
    }
    if (a.subjectName === "Reading and Writing" && b.subjectName === "Math") {
      return -1;
    }
    if (a.subjectName === "Math" && b.subjectName === "Reading and Writing") {
      return 1;
    }
    return 0;
  });
  return { sections: sortedSections };
}

export const validateData = (parsedData) => {
  const errors = [];
  return errors;
}